
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import Button from "@/components/Button.vue";

    @Component({
        components: {
            'te-button': Button,
        }
    })
    export default class LoginView extends Vue {
        private username: string|null = null;
        private password: string|null = null;
        @Prop() private isLoading?: boolean;
        @Action('login') login: any;
        // @Action('fetchPeriods') fetchPeriods: any;
        // @Action('fetchDateContext') fetchDateContext: any;
        // @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;


        public onLogin(): void {
            this.login({username: this.username, password: this.password});
        }

    }
